@media print {
  body {
    margin: 0;
    padding: 0;
  }

  .card {
    width: 210mm; /* A5 width */
    height: 148mm; /* A5 height */
    margin: 0;
    padding: 20px; /* Adjust as needed */
  }

 /* styles.css */

@page {
  size: a5 landscape;
  margin: 0mm !important;
}

  .modal-content {
    margin-top: 10mm; /* Add top margin of 10mm for the modal content */
   
  }

  .modal-header {
    text-align: center;
    margin-top: 6mm;
  }

  .modal-body {
    padding-left: 20px;
    padding-top: 8px;
    margin-left: 20px;
    text-align: center;
  } 

 .form-group {
    padding-top: 4mm; /* Add top padding of 10mm for the form-group */
    font-size: 16px;
    padding-left: 20px;
    margin-left: 20px;
    text-align: center;
  }

  .card-body {
    margin-top: 8mm; /* Add top margin of 10mm for the QR code */
    text-align: center;
  }
}

