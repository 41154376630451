/* Add this CSS in your styles file */

/* Style the notification dropdown */
.dropdown-menu {
    max-height: 60vh; /* Set the maximum height to 70% of the viewport height */
    overflow-y: auto; /* Add vertical scroll when the content overflows */
    max-width: 60vh;
  }
  
  /* Style the notification items */
  .dropdown-item {
    white-space: normal; /* Allow text to wrap within the items */
    padding: 10px 15px; /* Adjust padding as needed */
  }
  
  /* Style the header */
  .dropdown-header {
    background-color: #007bff; /* Set the header background color */
    color: #fff; /* Set the header text color */
    font-weight: 500; /* Set the header font weight */
    padding: 10px 15px; /* Adjust padding as needed */
  }
  