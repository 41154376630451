.heading{
    margin-bottom: 2rem;
    font-size: 1.5rem;
}

.pagination{
    margin-top: 2rem;
    display:flex;
    align-items: center;
    justify-content: center;
}
.pageNumbers{
    display: flex;
    padding: 0 1rem;
}
.num{
    width: 2rem;
    height: 2rem;
    display: grid;
       position: relative;
      left:300px;
      place-items: center ;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.2s ease-in-out;
}
.num:hover, .numActive{
    font-weight: 600;
}
.numActive{
    background-color: rgb(114, 114, 255);
    color: white;
}

.arrows:hover{
    
    outline: none;
}

.arrows {
    cursor: pointer;
    user-select: none;
    left:300px;
    position: relative;
    display: grid;
  }
  
  .arrows:active {
    outline: none; /* Remove the outline when active (clicked) */
  }
  

@media (max-width: 768px) {
    .heading {
        font-size: 1.2rem;
        /* Add any other responsive styles for heading */
    }

    .pagination {
        flex-direction: right;
        align-items: flex-start;
        margin-top: 1rem;
        padding-left: 1rem;
    }

    .pageNumbers{
        display: flex;
        padding: 0 1rem;
    }

    .num{
        width: 2rem;
        height: 3rem;
        display: grid;
        position: relative;
       left:150px;
       place-items: center ;
     border-radius: 5px;
     cursor: pointer;
     transition: 0.2s ease-in-out;
    }
    .arrows {
        cursor: pointer;
        user-select: none;
        left:150px;
        position: relative;
        display: grid;
    }
}

 