.subscription-page {
    width: 100%;
    max-width: 960px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .hero-section {
    text-align: center;
  }
  
  .hero-section h1 {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .hero-section h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .hero-section p {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 20px;
  }
  
  .hero-section .btn {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    text-decoration: none;
    cursor: pointer;
  }
  
  .plans-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .plan {
    width: 100%;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    padding: 20px;
  }
  
  .plan h2 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .plan p {
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  .plan .features {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .plan .features li {
    margin-bottom: 10px;
  }
  
